import {
  Button,
  Checkbox,
  Heading,
  LinkButton,
  StatusMessage,
  TextField,
  Text,
} from '@carvertical/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { Trans, useTranslation } from 'next-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import cx from 'classnames';
import { Link } from 'components/common/Link';
import * as dataLayer from 'services/dataLayer';
import { FormField } from 'components/common/ui/forms';
import { useRouteData } from 'context/RouteDataProvider';
import { useRecaptcha } from 'modules/auth';
import { isClient } from 'utils/ssr';
import { type BasicFormFields, basicFormSchema } from '../../schemas';
import { type BasicFormValues, MainActivity } from '../../types';
import { useB2bContactMutation, useCampaign } from '../../hooks';
import { CampaignSubmitConfirmation } from './CampaignSubmitConfirmation';

type CampaignFormProps = {
  ctaLabel?: string;
  ctaNote?: string;
  title: string;
  description: string;
  successTitle: string;
  successDescription: string;
  advantages?: string[];
  variant?: 'default' | 'light';
  headingSize?: 'l' | '2xl';
  nameLabel?: string;
};

const CAMPAIGN_MESSAGE = 'Campaign';
const FIELD_PROPS = {
  color: 'outlined',
  fullWidth: true,
  filled: true,
  requiredIndicatorShown: false,
};

const CampaignForm = ({
  ctaLabel,
  ctaNote,
  successTitle,
  title,
  description,
  successDescription,
  advantages,
  variant = 'default',
  headingSize = '2xl',
  nameLabel,
}: CampaignFormProps) => {
  const { t } = useTranslation(['business', 'common']);
  const { getCaptchaResponse } = useRecaptcha();
  const { market } = useRouteData();
  const { markCampaignAsSuccessful, partner } = useCampaign();

  const { contactB2b, contactingB2b, b2bContactError, b2bContacted, reset } =
    useB2bContactMutation();

  const form = useForm<BasicFormFields>({
    resolver: zodResolver(basicFormSchema),
  });
  const { formState, handleSubmit } = form;
  const submitting = formState.isSubmitting || contactingB2b;
  const success = formState.isSubmitSuccessful && b2bContacted;
  const error = b2bContactError;

  useEffect(() => {
    if (success) {
      markCampaignAsSuccessful();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [markCampaignAsSuccessful, success]);

  const buttonLabel = ctaLabel || t('common:general.getInTouchActionFormal');

  const submit = async (values: BasicFormValues) => {
    contactB2b({
      ...values,
      dealSourceUrl: window.location.href,
      mainActivity: MainActivity.CarDealer,
      message: getMessage(market.countryCode, partner),
      market: market.id,
      captchaResponse: await getCaptchaResponse(),
    });

    dataLayer.push('b2bFormSubmitted');
  };

  const Field = FormField<BasicFormFields>;

  return (
    <AnimatePresence initial={false} mode="wait">
      <div
        className={cx(
          'mx-auto flex max-w-72 flex-col gap-4 md:mx-0 md:gap-5 lg:pr-4',
          success && 'self-center md:mx-auto lg:pr-0',
        )}
      >
        {success ? (
          <CampaignSubmitConfirmation description={successDescription} title={successTitle} />
        ) : (
          <FormProvider {...form}>
            <div className="flex flex-col gap-2">
              <Heading as="h1" variant={headingSize}>
                {title}
              </Heading>

              <div>
                <Text variant={advantages?.length ? 'm' : 'l'}>{description}</Text>

                {!!advantages?.length && (
                  <ul className="m-0 pl-3.5">
                    {advantages.map((advantage) => (
                      <li key={advantage}>
                        <Text variant="m">{advantage}</Text>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            {error && (
              <StatusMessage
                closeButtonShown
                onCloseButtonClick={() => {
                  reset();
                }}
                title={t('common:general.errorTitle')}
                variant="error"
              >
                {t('common:general.errorText')}
              </StatusMessage>
            )}

            <form
              className="flex w-full flex-col items-stretch gap-2"
              onSubmit={handleSubmit(submit)}
            >
              <Field name="name">
                <TextField label={nameLabel || t('contactSection.labels.name')} {...FIELD_PROPS} />
              </Field>

              <Field name="email">
                <TextField label={t('contactSection.labels.email')} {...FIELD_PROPS} />
              </Field>

              <Field name="companyName">
                <TextField label={t('contactSection.labels.companyName')} {...FIELD_PROPS} />
              </Field>

              <Field name="termsAccepted">
                <Checkbox
                  label={
                    <Trans
                      i18nKey="contactSection.termsAccepted"
                      t={t}
                      as="span"
                      components={{
                        1: (
                          <LinkButton
                            as={Link}
                            href="/policies"
                            target="_blank"
                            size="m"
                            variant={variant === 'light' ? 'black' : 'blue'}
                          />
                        ),
                      }}
                    />
                  }
                />
              </Field>

              <div className="flex flex-col gap-1">
                <Button variant="yellow" type="submit" size="l" disabled={submitting}>
                  {submitting ? `${t('common:general.pleaseWaitLabel')}…` : buttonLabel}
                </Button>

                {ctaNote && (
                  <Text variant="xs" align="center">
                    {ctaNote}
                  </Text>
                )}
              </div>
            </form>
          </FormProvider>
        )}
      </div>
    </AnimatePresence>
  );
};

function getMessage(countryCode: string, partner?: string) {
  let url;
  let message = `${CAMPAIGN_MESSAGE}, country code: ${countryCode}`;

  if (isClient) {
    url = window.location.href;
  }

  if (partner) {
    message = `${message}, partner: ${partner}`;
  }

  if (url) {
    message = `${message}, url: ${url}`;
  }

  return message;
}

export { CampaignForm };
