import { j as n } from "./chunks/jsx-runtime.82a9ddf6.js";
import { c as s } from "./chunks/cn.fbf70112.js";
import { forwardRef as d } from "react";
import { m as p } from "./chunks/motion.bdfaf94a.js";
import { AnchorOrButton as u } from "./anchorOrButton.mjs";
const r = d(({ parentId: a, active: e, children: l, size: t, variant: m, ...o }, i) => /* @__PURE__ */ n.jsxs(
  u,
  {
    unstyled: !0,
    ref: i,
    role: "tab",
    className: s(
      "relative rounded-full text-xs+ outline-none transition-colors duration-300 hover:text-blue focus-visible:ring",
      t === "s" && "px-1.5 py-0.5",
      t === "m" && "px-1.5 py-0.75",
      t === "l" && "px-2 py-1 text-s+",
      e && "pointer-events-none text-white"
    ),
    "aria-selected": e,
    tabIndex: e ? 0 : -1,
    ...o,
    children: [
      /* @__PURE__ */ n.jsx("span", { className: "flex", children: l }),
      e && /* @__PURE__ */ n.jsx(
        p.div,
        {
          initial: !1,
          layoutId: `indicator-${a}`,
          className: "absolute inset-0 z-[-1] bg-blue",
          style: { borderRadius: "9999px" }
        }
      )
    ]
  }
));
try {
  r.displayName = "SegmentedControlOption", r.__docgenInfo = { description: "", displayName: "SegmentedControlOption", props: { as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, unstyled: { defaultValue: null, description: "", name: "unstyled", required: !1, type: { name: "boolean" } }, parentId: { defaultValue: null, description: "", name: "parentId", required: !1, type: { name: "string" } }, active: { defaultValue: null, description: "", name: "active", required: !1, type: { name: "boolean" } }, size: { defaultValue: null, description: "", name: "size", required: !1, type: { name: "SegmentedControlSize" } }, variant: { defaultValue: null, description: "", name: "variant", required: !1, type: { name: "SegmentControlVariant" } } } };
} catch {
}
export {
  r as SegmentedControlOption
};
