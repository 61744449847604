import { motion, type AnimationProps } from 'framer-motion';
import { Heading, Text } from '@carvertical/ui';
import CircleCheckIcon from 'assets/illustrations/circle-check.svg';

type CampaignSubmitConfirmationProps = {
  title: string;
  description: string;
};

const ANIMATION_PROPS: AnimationProps = {
  variants: {
    hidden: { opacity: 0, height: 0 },
    visible: { opacity: 1, height: 'auto' },
  },
  transition: {
    duration: 0.5,
    ease: 'easeInOut',
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
};

const CampaignSubmitConfirmation = ({ title, description }: CampaignSubmitConfirmationProps) => (
  <motion.div
    {...ANIMATION_PROPS}
    className="flex flex-col items-center justify-center gap-2 lg:min-h-[640px]"
  >
    <div className="flex flex-col items-center">
      <CircleCheckIcon original className="h-15 w-15" />

      <Heading as="h1" variant="2xl" align="center">
        {title}
      </Heading>
    </div>

    <Text variant="l" className="max-w-60" align="center">
      {description}
    </Text>
  </motion.div>
);

export { CampaignSubmitConfirmation };
