import { useTranslation } from 'next-i18next';
import { useRouteData } from 'context/RouteDataProvider';
import { Hero } from 'components/landings/common/Hero';
import type { BusinessPagePartialRouteData } from '../../types';
import { HeroIllustration } from '../HeroIllustration';
import { CampaignForm } from './CampaignForm';

const TYPE = 'business';

const CountryCampaignHero = () => {
  const {
    ctaLabel,
    successDescription,
    successTitle,
    title,
    description,
    advantages,
    ctaNote,
    nameLabel,
  } = useCountryCampaignHeroTranslations();
  const { campaignVersion } = useRouteData<BusinessPagePartialRouteData>();

  return (
    <Hero
      color="colorBlue100"
      className="!pb-6 !pt-0 lg:!py-0"
      leftContent={{
        title: undefined,
        subtitle: undefined,
        textShown: false,
        className: 'pt-6 lg:py-10',
        additionalContent: (
          <CampaignForm
            headingSize={campaignVersion === 'v2' ? 'l' : '2xl'}
            ctaLabel={ctaLabel}
            successDescription={successDescription}
            successTitle={successTitle}
            title={title}
            description={description}
            advantages={advantages}
            ctaNote={ctaNote}
            nameLabel={nameLabel}
          />
        ),
        identifier: null,
      }}
      rightContent={{
        className: 'overflow-hidden -mx-2 md:-mx-4 md:overflow-visible md:items-end',
        illustrationClassName: 'h-full',
        illustration: (
          <div className="relative lg:min-w-72">
            <div className="flex h-full w-full justify-start lg:absolute lg:right-0 lg:top-0 lg:justify-end">
              <HeroIllustration type={TYPE} size="auto" />
            </div>
          </div>
        ),
      }}
    />
  );
};

function useCountryCampaignHeroTranslations() {
  const { campaignVersion } = useRouteData<BusinessPagePartialRouteData>();
  const { t } = useTranslation(['business']);
  const isV2 = campaignVersion === 'v2';
  const advantages = t(`campaign.country.v2.description.advantages`, {
    returnObjects: true,
  });

  return {
    ctaLabel: `${t(`campaign.country.${campaignVersion}.cta`)}${isV2 ? `*` : ''}`,
    ctaNote: isV2 ? `*${t(`campaign.country.v2.ctaNote`)}` : undefined,
    nameLabel: isV2 ? t(`campaign.country.v2.nameLabel`) : undefined,
    successDescription: t(`campaign.country.successDescription`),
    successTitle: t(`campaign.country.successTitle`),
    title: t(`campaign.country.${campaignVersion}.title`),
    description: t(`campaign.country.${campaignVersion}.description.title`),
    advantages: isV2 && Array.isArray(advantages) ? advantages : undefined,
  };
}

export { CountryCampaignHero };
