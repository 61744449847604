import type { ParseKeys } from 'i18next';
import cx from 'classnames';
import Image from 'next/image';
import { Heading, Stack, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import styles from './TickerItem.module.scss';

type TickerItemColorVariant = 'black' | 'white' | 'blue';

type TickerBaseProps = { className?: string; id: string };

type TickerItemWithText = {
  descriptionKey: ParseKeys<'home'>;
  title: React.ReactNode;
  color: TickerItemColorVariant;
  gap?: number;
  footer?: React.ReactNode;
} & TickerBaseProps;

type TickerItemWithImage = {
  image: string;
} & TickerBaseProps;

type TickerItemProps = TickerItemWithText | TickerItemWithImage;

const TickerItem = (item: TickerItemProps) => {
  const { t } = useTranslation('home');

  if ('image' in item) {
    const { image, id, className } = item;
    return (
      <div className={cx(styles.imageWrapper, styles.card, className)} key={id}>
        <Image src={image} className={styles.image} alt="" fill />
      </div>
    );
  }

  const { className, color = 'blue', descriptionKey, id, footer, title } = item;
  const titleIsString = typeof title === 'string';

  return (
    <Stack
      gap={2}
      key={id}
      className={cx(styles.card, styles.fact, styles[color], className)}
      crossAxisAlign="center"
      mainAxisAlign="center"
    >
      {titleIsString ? (
        <Heading variant="m" as="strong" textColor="inherited" align="center">
          {title}
        </Heading>
      ) : (
        title
      )}

      {titleIsString && <div className={styles.divider} />}

      <Text variant="s" as="span" textColor="inherited" align="center">
        {t(descriptionKey)}
      </Text>

      {footer}
    </Stack>
  );
};

export { TickerItem };
export type { TickerItemProps, TickerItemWithText };
